import React from "react";
import { Box, Container, Typography } from "@mui/material";
import financePlan from "../../../assets/Images/luis-villasmil-4V8uMZx8FYA-unsplash.jpg";
const FinancePlan = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          px: {
            xs: 2,
            sm: 5,
            md: 3.5,
          },
          my: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <img
              src={financePlan}
              alt=""
              style={{
                borderRadius: "24px",
                maxWidth: "100%",
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#161414",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              UpAI Financial Planning: Elevate Your Investment Game
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              Dive deep into the intricacies of financial planning with UpAI's
              expert consultation services. We meticulously analyze your
              business, assess risks, and delve into global statistical data.
              Our approach involves crafting tailored strategy projects and
              providing step-by-step consultations, ensuring your financial
              journey is guided by expertise and precision. UpAI - where your
              financial goals meet strategic brilliance.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FinancePlan;
