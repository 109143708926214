import React from "react";
import { Box, Container, Typography } from "@mui/material";
import FeaturesVerticalCard from "../features/FeaturesVerticalCard";

const FinanceDetails = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          px: {
            xs: 2,
            sm: 5,
            md: 3.5,
          },
          my: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FeaturesVerticalCard />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#161414",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Find services to grow your business
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              We have saved all annual investigations from business, economic,
              marketing and financial fields. Here you will be able to inspect
              market positions based on our available services. Learn more here
              for your next action
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FinanceDetails;
