import React from "react";
import "./TeamCard.css";
import dinesh from "../../../assets/Images/dinesh-upai.jpg";
import saurav from "../../../assets/Images/saurav-upai.png";
import sapna from "../../../assets/Images/Sapna-upai.jpg";
import vinay from "../../../assets/Images/vinay-upai.jpg";
import ketan from "../../../assets/Images/ketan-upai.jpg";
import bhaskar from "../../../assets/Images/bhaskar-upai.jpg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
interface TeamMemberProps {
  name: string;
  title: string;
  imageUrl: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  title,
  imageUrl,
  facebook,
  twitter,
  linkedin,
}) => (
  <div className="member">
    <img src={imageUrl} alt={name} />
    <h3>{name}</h3>
    <p>{title}</p>
    <div className="caption">
      {linkedin && (
        <a href={linkedin} target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
        </a>
      )}
      {twitter && (
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </a>
      )}
    </div>
  </div>
);
const TeamCard: React.FC = () => {
  return (
    <>
      <section>
        <div className="team-row">
          <TeamMember
            name="Swapna Panjala"
            title="CFO"
            imageUrl={sapna}
            twitter=""
            linkedin="https://www.linkedin.com/in/ca-swapna-panjala"
          />
          <TeamMember
            name="Saurav L. Chaudhari"
            title="COO & CIO"
            imageUrl={saurav}
            twitter="https://twitter.com/mK47_Hypersonic"
            linkedin="https://in.linkedin.com/in/sauravlchaudhari"
          />
          <TeamMember
            name="Ketan Pise"
            title="CEO & CTO"
            imageUrl={ketan}
            twitter="https://twitter.com/P_Ktnn"
            linkedin="https://www.linkedin.com/in/kppise/"
          />

          <TeamMember
            name="Vinay Makade"
            title="Full Stack Developer"
            imageUrl={vinay}
            twitter="https://twitter.com/MakadeVinay"
            linkedin="https://www.linkedin.com/in/vinay-makade-239a38133/"
          />
          <TeamMember
            name="Dinesh Fukate"
            title="Chief Public Relations"
            imageUrl={dinesh}
            twitter="https://twitter.com/DineshFukate"
            linkedin="https://www.linkedin.com/in/dineshfukate/"
          />
          <TeamMember
            name="Bhaskar Reddy"
            title="Business Analyst executive"
            imageUrl={bhaskar}
            twitter="https://x.com/bhaskar42907262?s=21&t=jXzl_o9IhfhSILbWCYBBng"
            linkedin="https://www.linkedin.com/in/bhaskar-reddy-590542245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          />
        </div>
      </section>
    </>
  );
};

export default TeamCard;
