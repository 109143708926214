import React from "react";

import { Box, Container, Typography } from "@mui/material";
const CommunitySection = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#A3BDFF",
          width: "100%",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Container
            disableGutters
            maxWidth="lg"
            sx={{
              px: {
                xs: 1,
                sm: 3,
                md: 2,
              },
              my: 15,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "center",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "800",
                    fontSize: "28px",
                    lineHeight: "48px",
                    textAlign: {
                      xs: "center",
                      md: "center",
                    },
                  }}
                >
                  Interested in joining our community?
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "32px",
                    my: 4,
                    textAlign: {
                      xs: "center",
                      md: "center",
                    },
                  }}
                >
                  If you are ready to be the best in your business we are
                  pleased to help you with the competent support. Any question
                  will be satisfied.
                </Typography>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default CommunitySection;
