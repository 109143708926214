import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import HomeIcon from "@mui/icons-material/Home";
import logoImg from "../../../assets/Images/UpAI_Trading-logo.png";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Container } from "@mui/system";
import {
  Backdrop,
  Drawer,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../customButton/CustomButton";

export const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState({
    left: false,
  });
  const [openModal, setOpenModal] = useState(false);

  const navItems = [
    {
      key: "Home",
      redirectTo: "/",
    },
    {
      key: "What We Do",
      redirectTo: "/whatWeDo",
    },
    {
      key: "About Us",
      redirectTo: "/aboutUs",
    },
    // ,
    // {
    //   key: "Contact",
    //   redirectTo: "/",
    // },
  ];
  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.type === "Tab" || event.type === "Shift")
    ) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  const list = (anchor: any) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navItems.map((text, index) => (
          <ListItem key={text.key}>
            <ListItemButton
              onClick={() => {
                navigate(text.redirectTo);
              }}
            >
              <ListItemIcon>
                {index === 0 && <HomeIcon />}
                {index === 1 && <FeaturedPlayListIcon />}
                {index === 2 && <ContactsIcon />}
              </ListItemIcon>
              <ListItemText primary={text.key} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const NavLink = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#4F5361",
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      color: "#fff",
    },
  }));

  const NavbarLinksBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const CustomMenuIcon = styled(MenuIcon)(({ theme }) => ({
    cursor: "pointer",
    display: "none",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  }));

  const NavbarContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "3vh",
    paddingBottom: "3vh",
    background: "#E6F0FF",
  }));

  const NavbarLogo = styled("img")(({ theme }) => ({
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const StyledTypography = styled(Typography)(({ theme }) => ({
    flexGrow: 1,
    paddingLeft: "10px",
    fontWeight: 800,
    fontSize: "30px",
    color: "#000336",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }));

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 5,
    '@media (max-width: 700px)': {
      width: 400,
    },
    '@media (max-width: 450px)': {
      width: 300,
    }
  };


  const navigate = useNavigate();

  return (
    <>
    <NavbarContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomMenuIcon onClick={toggleDrawer("left", true)} />
          <Drawer
            anchor="left"
            open={mobileMenu["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>
          <NavbarLogo
            src={logoImg}
            alt="logo"
            style={{ width: "70px", height: "50px" }}
          />
          <StyledTypography variant="h6">UpAI</StyledTypography>
        </Box>
        <NavbarLinksBox>
          {navItems.map((item) => (
            <NavLink
              onClick={() => {
                navigate(item.redirectTo);
              }}
            >
              {item.key}
            </NavLink>
          ))}
        </NavbarLinksBox>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        {/* <NavLink variant="body2">Sign Up</NavLink> */}
        <CustomButton
          backgroundColor="#0F1B4C"
          color="#fff"
          buttonText="Join Waitlist"
          onClick={handleModalOpen}
        />
        {/* <NavbarLinksBox>
          {navItems.map((item) => (
            <ListItem key={item.key}>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText
                  primary={item.key}
                  onClick={() => {
                    navigate(item.redirectTo);
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </NavbarLinksBox> */}
      </Box>
    </NavbarContainer>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
          <Box sx={modalStyle}>
            <iframe title="waitlistForm" style={{borderRadius: 5}} src="https://bit.ly/join-UpAI" width='100%' height='100%'> </iframe>
          </Box>
        </Fade>
    </Modal>
    </>
  );
};

export default Navbar;
