import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "../home/Home";
import WhatWeDo from "../whatWeDo/WhatWeDo";
import Navbar from "../common/navbar/Navbar";
import Footer from "../common/footer/Footer";
import About from "../about/About";
const Pages = () => {
  return (
    <>
      <HashRouter>
        <div style={{ background: "#E6F0FF" }}>
          <Navbar />
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whatWeDo" element={<WhatWeDo />} />
          <Route path="/aboutUs" element={<About />} />
        </Routes>
      </HashRouter>
      <Footer />
    </>
  );
};

export default Pages;
