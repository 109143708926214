import React from "react";
import { financeDetailsData, FeatureItem } from "../../data/data";
import { Box } from "@mui/system";
interface FeaturedData extends Array<FeatureItem> {}

const featured: FeaturedData = financeDetailsData;

const FeaturesVerticalCard: React.FC = () => {
  return (
    <>
      <div className="content mtop" style={{ justifyContent: "center" }}>
        {featured.map((item, index) => (
          <Box
            className="box "
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              boxShadow: "0 0 20px 0 rgb(112 121 138 / 18%)",
              padding: "40px",
              margin: "20px",
              background: "white",
            }}
          >
            <img
              src={item.cover}
              alt=""
              style={{
                display: "inline-block",
                width: "60px",
                height: "60px",
              }}
            />

            <div style={{ paddingLeft: "10px" }}>
              <h2>{item.name}</h2>
              <label style={{ color: "#8d97a9" }}>{item.description}</label>
            </div>
          </Box>
        ))}
      </div>
    </>
  );
};

export default FeaturesVerticalCard;
