import React from "react";
import { features, FeatureItem } from "../../data/data";

interface FeaturedData extends Array<FeatureItem> {}

const featured: FeaturedData = features;

const FeaturesHorizontalCard: React.FC = () => {
  return (
    <>
      <div className="content grid3 mtop">
        {featured.map((item, index) => (
          <div className="box" key={index} style={{ background: "white" }}>
            <img src={item.cover} alt="" />
            <h4>{item.name}</h4>
            <label>{item.description}</label>
          </div>
        ))}
      </div>
    </>
  );
};

export default FeaturesHorizontalCard;
