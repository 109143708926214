import React from "react";
import Heading from "../../common/Heading";
import "./Features.css";
import FeaturesHorizontalCard from "./FeaturesHorizontalCard";

const Features = () => {
  return (
    <>
      <section className="featured background">
        <div className="container">
          <Heading title="Why Choose UpAI?" subtitle="" />
          <FeaturesHorizontalCard />
        </div>
      </section>
    </>
  );
};

export default Features;
