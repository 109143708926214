import React from "react";
import "./FeatureMoneyBox.css";
const FeaturesMoneyBox: React.FC = () => {
  return (
    <>
      <div style={{ textAlign: "center", fontSize: "24px", fontWeight: "600" }}>
        UpAI Ultra Plan : For Large Enterprises
      </div>
      <div className="content grid2 mtop">
        <div
          className="box shadow  pink-bg"
          style={{ textAlign: "center", padding: "20px", background: "white" }}
        >
          <div
            style={{
              backgroundColor: "#1F1D42",
              color: "white",
              padding: "5px",
            }}
          >
            RX1
          </div>
          <div className="padding" style={{ paddingTop: "20px" }}>
            <strong>25 Advanced Analytics </strong>Campaigns
          </div>
          <div className="padding">
            <strong>Optimization </strong>Session
          </div>
          <div className="padding">
            <strong>24/7 </strong>Dedicated Support
          </div>
          <div className="padding">
            <strong>Relationship </strong> Manager
          </div>
        </div>
        <div
          className="box shadow  pink-bg"
          style={{ textAlign: "center", padding: "20px", background: "white" }}
        >
          <div
            style={{
              backgroundColor: "#1F1D42",
              color: "white",
              padding: "5px",
            }}
          >
            RX2
          </div>
          <div className="padding" style={{ paddingTop: "20px" }}>
            <strong>108 Analytics </strong>Campaigns
          </div>
          <div className="padding">
            <strong>Optimization </strong>Sessions
          </div>
          <div className="padding">
            <strong>24/7 </strong>Dedicated Support
          </div>
          <div className="padding">
            <strong>Relationship </strong> Manager
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "10px",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Choose UpAI Funds and witness your financial future flourish.
      </div>
    </>
  );
};

export default FeaturesMoneyBox;
