import React from "react";
import { Box, Container, Typography } from "@mui/material";
import newsImage from "../../../assets/Images/pexels-yan-krukau-7693699.jpg";
const CompetitionAnalysis = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          px: {
            xs: 2,
            sm: 5,
            md: 3.5,
          },
          my: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#161414",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Gain Strategic Insights
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              If you need to know who was the best at the market and which
              position was replaced, try our award-winning service. It is also
              possible to know the startup’s priorities. Besides, take
              information from the Knowledge Centre. For getting particular
              information for your business, you will be able to consult with
              us.
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <img
              src={newsImage}
              alt=""
              style={{
                borderRadius: "24px",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default CompetitionAnalysis;
