import React from "react";
import { footer } from "../../data/data";
import "./Footer.css";
import linkedin from "../../../assets/Images/linkedin.png";
import instagram from "../../../assets/Images/instagram.png";
import youtube from "../../../assets/Images/youtube.png";
import twitter from "../../../assets/Images/twitter.png";
import facebook from "../../../assets/Images/facebook.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div>
            <div>
              <h2> UpAI: Elevating Your Financial Horizon</h2>
              <p>
                Experience financial empowerment with UpAI. We revolutionize
                your financial strategies, providing cutting-edge solutions
                rooted in data-driven insights. Stay ahead of the game and make
                informed decisions. With UpAI, financial success is not a
                destination; it's a journey
              </p>
              <div style={{ display: "flex" }}>
                <div style={{ width: "24px", height: "24px" }}>
                  <a
                    href="https://www.linkedin.com/company/up-ai/"
                    target="self"
                  >
                    <img
                      src={linkedin}
                      alt="Linkedin"
                      style={{
                        borderRadius: "10px",
                        maxWidth: "100%",
                      }}
                    />
                  </a>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <a href="https://www.facebook.com/upai.tech" target="self">
                    <img
                      src={facebook}
                      alt="facebook"
                      style={{
                        borderRadius: "24px",
                        maxWidth: "100%",
                      }}
                    />
                  </a>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <a href="https://www.instagram.com/upai.tech/" target="self">
                    <img
                      src={instagram}
                      alt="instagram"
                      style={{
                        borderRadius: "24px",
                        maxWidth: "100%",
                      }}
                    />
                  </a>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <a href="https://twitter.com/UpAI_tech" target="self">
                    <img
                      src={twitter}
                      alt="twitter"
                      style={{
                        borderRadius: "24px",
                        maxWidth: "100%",
                      }}
                    />
                  </a>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <a href="https://www.youtube.com/@UpAI-xzent" target="self">
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{
                        borderRadius: "24px",
                        maxWidth: "100%",
                      }}
                    />
                  </a>
                </div>
              </div>
              {/* <div className="input flex">
                <input type="text" placeholder="Email Address" />
                <button>Subscribe</button>
              </div> */}
            </div>
          </div>

          {footer.map((val) => (
            <div className="col">
              <h3>{val.title}</h3>

              {val.text.map((items) => (
                <p> {items.list} </p>
              ))}
            </div>
          ))}
        </div>
        <hr />
        <p className="copyright">
          Copyright © 2023 UpAI XZENT SOLUTIONS PRIVATE LIMITED.
        </p>
      </footer>
    </>
  );
};

export default Footer;
