import React from "react";
import Features from "../home/features/Features";
import FinancePlan from "./financePlan/FinancePlan";

const WhatWeDo = () => {
  return (
    <>
      <FinancePlan />
      <Features />
    </>
  );
};

export default WhatWeDo;
