import React from "react";
import { Box, Container, Typography } from "@mui/material";
const OurVision = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          px: {
            xs: 2,
            sm: 5,
            md: 3.5,
          },
          my: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            gap: 4,
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ flex: 1 }}>
            <img
              src={financePlan}
              alt=""
              style={{
                borderRadius: "24px",
                maxWidth: "100%",
              }}
            />
          </Box> */}
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#161414",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              Our Journey
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              UpAI's story is woven with the collective vision of Saurav, Ketan,
              Vinay, and Dinesh - a team brought together by a shared passion
              for unraveling the complexities of the stock market. Rooted in the
              same engineering background, our journey began in 2022 when we
              identified a void in the investment landscape - the scarcity of
              reliable stock market insights.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              Driven by this mission, we set out to change the game. Fuelled by
              our combined expertise in AI, finance, and technology, we
              conceived "Marker." This innovative platform harnesses the power
              of Artificial Intelligence (AI) through robust methodologies such
              as Central Limit Theorem, Regression Analysis, and Reinforcement
              Learning.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              At UpAI, we're more than just algorithms; we're about empowering
              investors of all levels with the tools they need. Our diverse
              team, with its cohesive coordination and complementary expertise,
              is committed to democratizing stock market analytics. We aim to
              provide every investor with the clarity and confidence to navigate
              the intricate world of investments.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default OurVision;
