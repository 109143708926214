export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "blog",
    path: "/blog",
  },
  {
    text: "pricing",
    path: "/pricing",
  },
  {
    text: "contact",
    path: "/contact",
  },
];
export interface FeatureItem {
  name: string;
  cover: string;
  description: string;
}
export const features = [
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon01.png",
    name: "Harness the Power of Data",
    description:
      "Navigate the financial landscape with our data-driven insights. Stay informed about market trends and potential opportunities.",
  },
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon02.png",
    name: "Plan Your Path to Success",
    description:
      "Craft effective business strategies with our expert guidance. We assist you in identifying and mitigating risks, ensuring sustainable growth.",
  },
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon03.png",
    name: "Embrace Digital Transformation",
    description:
      "Leverage our expertise to integrate digital solutions into your long-term vision. Engage with your audience seamlessly in the digital realm.",
  },
];

export const newsImage =
  "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-content-bg04.jpg";

export const financeSuccessImg =
  "https://img.freepik.com/free-photo/office-cityscape-builidings-contemporary-interior-room-modern-concept_53876-14120.jpg?w=826&t=st=1698838338~exp=1698838938~hmac=9bd9dac556fdc7e707fe896774b86b61f00d7dc6e3040bd616a46292aa66a147";

export const communitySectionImg =
  "https://img.freepik.com/free-photo/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner_1258-56228.jpg?w=1060&t=st=1698851512~exp=1698852112~hmac=69716b2560c1fa97819139d4c72563774690f72c1226137cd7315366a40e11e6";

export const footerImg =
  "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-footer-img.png";

export const financePlan =
  "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-content-bg06.png";

export const introImg =
  "https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvam9iNjc5LTEyMy14LmpwZw.jpg";
export const financeDetailsData = [
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon04.png",
    name: "Business consultation with partners",
    description: "Free day-and-night chat",
  },
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon05.png",
    name: "Financial planning for projects",
    description: "Annual data available",
  },
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon06.png",
    name: "Change management board",
    description: "Your online goals result",
  },
  {
    cover:
      "https://template92321.motopreview.com/mt-demo/92300/92321/mt-content/uploads/2019/12/mt-1944-icon07.png",
    name: "Investment planning without risks",
    description: "Statistical information",
  },
];

export const footer = [
  {
    title: "COMPANY",
    text: [
      { list: "About us" },
      { list: "Team" },
      { list: "Publications" },
      { list: "Terms and Conditions" },
      { list: "Privacy policy" },
    ],
  },
  {
    title: "Community",
    text: [
      { list: "Security" },
      { list: "Faq" },
      { list: "Blog" },
      { list: "​​​​​​​Contact us" },
    ],
  },
];
