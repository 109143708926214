import React from "react";
import OurVision from "./ourVision/OurVision";
import Team from "./team/Team";
const About = () => {
  return (
    <>
      <OurVision />
      <Team />
    </>
  );
};

export default About;
