import React from "react";
import "./FinanceSuccess.css";
import newsImage from "../../../assets/Images/pexels-leeloo-thefirst-7887854.jpg";

import { Box, Container, Typography } from "@mui/material";
const FinanceSuccess = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div className="container">
          <Container
            disableGutters
            maxWidth="lg"
            sx={{
              px: {
                xs: 1,
                sm: 3,
                md: 2,
              },
              my: 15,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "center",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <img
                  src={newsImage}
                  alt=""
                  style={{
                    borderRadius: "24px",
                    maxWidth: "100%",

                    height: "500px",
                  }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                {/* <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "22px",
                    lineHeight: "48px",
                    // color: "#ffff",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  Discover the power of strategic financial planning with UpAI
                </Typography> */}
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "32px",
                    lineHeight: "48px",
                    color: "#161414",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  Discover the power of strategic financial planning with UpAI
                </Typography>
                {/* <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "32px",
                    // color: "#ffff",
                    my: 4,
                  }}
                >
                  We specialize in comprehensive financial and investment
                  planning solutions tailored for your unique needs.
                </Typography> */}

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "32px",
                    color: "#5B5B5B",
                    my: 4,
                  }}
                >
                  We specialize in comprehensive financial and investment
                  planning solutions tailored for your unique needs.
                </Typography>

                {/* <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "22px",
                    lineHeight: "48px",
                    // color: "#ffff",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  Smart Financial Choices
                </Typography> */}

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "32px",
                    lineHeight: "48px",
                    color: "#161414",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                  }}
                >
                  Smart Financial Choices
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "32px",
                    color: "#5B5B5B",
                    my: 4,
                  }}
                >
                  Make wise investment decisions and secure your future with
                  UpAI's expert guidance. Explore bespoke financial strategies
                  designed exclusively for you.
                </Typography>

                {/* <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "32px",
                    // color: "#ffff",
                    my: 4,
                  }}
                >
                  Make wise investment decisions and secure your future with
                  UpAI's expert guidance. Explore bespoke financial strategies
                  designed exclusively for you.
                </Typography> */}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FinanceSuccess;
