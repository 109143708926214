import React from "react";
import Intro from "./intro/Intro";
import Features from "./features/Features";
import News from "./news/News";
import FinanceDetails from "./financeDetails/FinanceDetails";
import CompetitionAnalysis from "./competitionAnalysis/CompetitionAnalysis";
import FinanceSuccess from "./financesuccess/FinanceSuccess";
import FundUs from "./fundUs/FundUS";
import CommunitySection from "./communitySection/CommunitySection";

const Home = () => {
  return (
    <>
      <Intro />
      <Features />
      <News />
      <FinanceDetails />
      <CompetitionAnalysis />
      <FinanceSuccess />
      <FundUs />
      <CommunitySection />
    </>
  );
};

export default Home;
