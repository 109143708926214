import React from "react";
import { Box, Container, Typography } from "@mui/material";
import FeaturesMoneyBox from "../features/FeatureMoneyBox";

const FundUs = () => {
  return (
    <>
      <Container
        disableGutters
        maxWidth="lg"
        sx={{
          px: {
            xs: 2,
            sm: 5,
            md: 3.5,
          },
          my: 15,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#161414",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              UpAI Funds: Empowering Financial Growth
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "32px",
                color: "#5B5B5B",
                my: 4,
              }}
            >
              Unlock the potential of your investments with UpAI Funds. Tailored
              for businesses of all sizes, our financial solutions ensure your
              investments work smarter and grow steadily.
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FeaturesMoneyBox />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FundUs;
