import React from "react";

import { Box, Container, Typography } from "@mui/material";
import newsImage from "../../../../src/assets/Images/jason-briscoe-amLfrL8LGls-unsplash.jpg";
const News = () => {
  return (
    <Container
      disableGutters
      maxWidth="lg"
      sx={{
        px: {
          xs: 2,
          sm: 5,
          md: 3.5,
        },
        my: 15,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "center",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <img
            src={newsImage}
            alt=""
            style={{
              borderRadius: "24px",
              maxWidth: "100%",
            }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "32px",
              lineHeight: "48px",
              color: "#161414",
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            A wide range of business and financial services
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#5B5B5B",
              my: 4,
            }}
          >
            The latest researching from International Data-Analyzing University
            demonstrated that IT-sphere and Agricultural are on the top of
            growth. The number of investment capital had increased by 20-25%.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default News;
